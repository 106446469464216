import React, { useState, useContext, useEffect } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { Input } from 'components/ui/input';
import { Description, Field, Label } from 'components/catalyst/fieldset';
import { Textarea } from 'components/catalyst/textarea';
import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox';
import { AppContext } from 'contexts/AppContext';
import History from './history';
import Monitoring from './monitoring';
import { Table } from './table/Index'
import { Search, Filter} from 'lucide-react'

export default function DeploymentPage() {
  const { multiAgents } = useContext(AppContext)

  const columns = multiAgents.length > 0 ? Object.keys(multiAgents[0]) : [];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      // Clear the input when the Enter key is pressed
      setSearchQuery("");
    }
  };


  return (
    <div className="w-full h-[75vh] mt-4">
      <h1 className="text-lg font-semibold mb-4">My Workspaces</h1>

      <div className="relative flex items-center mb-4 ">
        <div className="relative flex items-center w-[400px]">
            <Search className="absolute left-3 w-5 h-5 text-gray-500" />
            <Input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
            placeholder="Search"
            className="w-full py-2 pl-10 pr-12 border border-gray-300 rounded-md "
            />
        </div>
        <button className="flex items-center h-[40px] justify-center p-2 border border-gray-300 rounded-md bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2">
            <Filter className="w-5 h-5 text-gray-500" />
        </button>
      </div>
      <Table selectedData={multiAgents} columns={columns} />
    </div>
  );
}