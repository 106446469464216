import React from 'react';
import { Button } from "components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "components/ui/card";
import { Badge } from "components/ui/badge";
import { PenSquare, Zap } from "lucide-react";
import {
  Dialog,
  DialogTrigger,
} from "components/ui/dialog";
import CreateEditAgent from './CreateAgent/Index';

export default function AgentCard({ agent, created }) {
  return (
    <Card className="flex flex-col">
      <CardHeader className="space-y-2">
        <CardTitle className="text-lg font-semibold">{agent.title}</CardTitle>
        <div className="inline-block w-auto">
          <Badge 
            variant="secondary" 
            className={`${
              agent.tag === 'Sales' ? 'bg-green-100 text-green-800 hover:bg-green-200' :
              agent.tag === 'DevOps' ? 'bg-purple-100 text-purple-800 hover:bg-purple-200' :
              agent.tag === 'Custom' ? 'bg-blue-100 text-blue-800 hover:bg-blue-200' :
              agent.tag === 'CustomerService' ? 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200' :
              'bg-red-100 text-red-800 hover:bg-red-200'
            }`}
          >
            #{agent.tag}
          </Badge>
        </div>
      </CardHeader>
      <CardContent className="flex-grow">
        <p className="text-sm text-gray-600">{agent.description}</p>
      </CardContent>
      <CardFooter className="flex justify-between items-center mt-auto">
        <div className="flex items-center space-x-2 text-sm text-gray-500">
          {agent.action_count && 
          <>
          <Zap className="w-4 h-4" />
          <span>{agent.action_count} actions</span>
          <span>Version {agent.version}</span>
          </>
          }
        </div>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant={created ? "outline" : "default"}>
              {created ? "Edit" : "Create"}
            </Button>
          </DialogTrigger>
          <CreateEditAgent agent={agent} />
        </Dialog>
      </CardFooter>
    </Card>
  );
}
