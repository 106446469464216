import React, { useState, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { themeQuartz } from 'ag-grid-community'
import { Check } from 'lucide-react'

const MemoizedAgGrid = React.memo(({ rowData, columnDefs, defaultColDef, statusBar }) => (
  <AgGridReact
    rowData={rowData}
    columnDefs={columnDefs}
    defaultColDef={defaultColDef}
    statusBar={statusBar}
    theme={themeQuartz}
    pagination={true}
  />
))

export function Table({ data }) {
  // Columns for agent_revisions
  const columns = ['deployed', 'name', 'traffic', 'deployed_since']

  const labelMap = {
    deployed: '', // or "Status" if you prefer a header
    name: 'Revision Name',
    traffic: 'Traffic',
    deployed_since: 'Deployed',
  }

  const [columnDefs, setColumnDefs] = useState([])

  useEffect(() => {
    const newDefs = columns.map((col) => {
      const colDef = {
        field: col,
        headerName: labelMap[col] ?? col,
        sortable: true,
        filter: true,
        resizable: true,
      }

      // Leftmost column: custom cell renderer for "deployed" status
      if (col === 'deployed') {
        // We can make it pinned left so it stays visible
        colDef.pinned = 'left'
        colDef.width = 60 // small, just enough for our icon

        // For older versions of AG Grid React: colDef.cellRendererFramework
        colDef.cellRenderer = (params) => {
          const isDeployed = params.value === true
          return (
            <div
              className={
                'inline-flex items-center justify-center w-6 h-6 rounded-full ' +
                (isDeployed ? 'bg-green-500' : 'bg-gray-400')
              }
            >
              <Check size={14} className="text-white" />
            </div>
          )
        }
      }

      return colDef
    })
    setColumnDefs(newDefs)
  }, [])

  // Default col definition
  const defaultColDef = useMemo(() => ({
    resizable: true,
    filter: true,
    sortable: true,
  }), [])

  // Simple status bar
  const statusBar = useMemo(() => ({
    statusPanels: [
      {
        statusPanel: 'agAggregationComponent',
        align: 'left',
      },
    ],
  }), [])

  return (
    <div className="h-full w-full flex flex-col mb-4">
      <div className="flex-1">
        {data && data.length > 0 ? (
          <MemoizedAgGrid
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            statusBar={statusBar}
          />
        ) : (
          <p className="text-center text-gray-500 mt-4">
            No data to display.
          </p>
        )}
      </div>
    </div>
  )
}
