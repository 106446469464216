import React, { useState, useContext } from 'react';
import './globals.css';  
import { SidebarLayout } from 'components/catalyst/sidebar-layout';
import { Toaster } from "components/ui/sonner"
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import AgentsPage from 'pages/agents/Index';
import ProfilePage from 'pages/profile/Index';

import { Auth0Provider } from '@auth0/auth0-react';
import AuthGuard from 'utils/AuthGuard'

import { SidebarContent } from 'components/layout/SidebarContent'
import { Header } from 'components/layout/Header'

import Deployments from 'pages/workspaces/Index';
import BuildCreatePage from 'pages/build/templates/Index'
import BuildFlowPage from 'pages/build/buildflow/Index'
import DetailsPage from 'pages/details/Index'
import BuildPage from 'pages/build/Index'
import ActionPage from 'pages/actions/Index'

import { ModuleRegistry, createGrid } from "ag-grid-community";
import { AllEnterpriseModule, LicenseManager } from "ag-grid-enterprise";

ModuleRegistry.registerModules([AllEnterpriseModule]);

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-074733}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Instalily}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{instalily-odyssey}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{instalily-odyssey}_need_to_be_licensed___{instalily-odyssey}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{9_January_2026}____[v3]_[01]_MTc2NzkxNjgwMDAwMA==2a1dc8e3e0253eef15323a2abdfb20cc");


function App() {
  
  return (
    <Auth0Provider
    domain="instalily.us.auth0.com"
    clientId="gmTPdOhz8cC4QtuDUNFFu9ZBY2FO675l"
    cacheLocation={"localstorage"}
    authorizationParams={{
      redirect_uri: typeof window !== 'undefined' ? window.location.origin : 'https://localhost:3000',
        audience: "https://instalily.us.auth0.com/api/v2/"
    }}>
    <AuthGuard>
        <SidebarLayout sidebar={<SidebarContent />}>
          <Header/>
          <Routes>
            <Route
              path="*"
              element={
                  <Routes>
                    <Route path="/" element={<Deployments />} />
                    <Route path="/Workspaces" element={<Deployments />} />
                    <Route path="/Workspaces/:id" element={<DetailsPage />} />
                    <Route path="/build" element={<BuildPage />} />
                    <Route path="/build/:id" element={<BuildFlowPage />} />
                    <Route path="/composable-agents" element={<AgentsPage />} />
                    <Route path="/actions" element={<ActionPage />} />
                    <Route path="/profile" element={<ProfilePage />} />
                  </Routes>
              }
            />
          </Routes>
          <Toaster />
        </SidebarLayout>
    </AuthGuard>
    </Auth0Provider>
  );
}

export default App;