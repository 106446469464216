import React, {useState} from "react"
import { Check } from "lucide-react"

export default function NetworkingPage() {
  const [internalAllowed, setInternalAllowed] = useState(true)
  const [externalAllowed, setExternalAllowed] = useState(false)
  const [allAllowed, setAllAllowed] = useState(false)

  return (
    <div className="h-full grid grid-cols-1 gap-4 md:grid-cols-3">
      {/* First Card: Ingress with checkboxes */}
      <div className="border rounded-md p-4">
        <h2 className="mb-3 text-lg font-semibold">Ingress</h2>

        {/* "Internal" checkbox */}
        <div className="flex items-start gap-2 mb-2">
          <input
            type="checkbox"
            checked={internalAllowed}
            onChange={(e) => setInternalAllowed(e.target.checked)}
            className="h-4 w-4 mt-1 accent-green-600"
            id="internal-box"
          />
          <div>
            <label
              htmlFor="internal-box"
              className="font-medium cursor-pointer"
            >
              Internal
            </label>
          </div>
        </div>
        
        <p className="text-sm text-gray-700 mb-2">
          Allow traffic from your project, shared VPC, and VPC service controls
          perimeter.
        </p>
        <div className="flex items-start gap-2 mb-2">
          <input
            type="checkbox"
            checked={externalAllowed}
            onChange={(e) => setExternalAllowed(e.target.checked)}
            className="h-4 w-4 mt-1 accent-green-600"
            id="internal-box"
          />
          <div>
            <label
              htmlFor="internal-box"
              className="font-medium cursor-pointer"
            >
              External
            </label>
          </div>
        </div>
        <p className="text-sm text-gray-700 mb-4">
          Allow traffic from external application load balancers
        </p>

        {/* "All" checkbox */}
        <div className="flex items-start gap-2">
          <input
            type="checkbox"
            checked={allAllowed}
            onChange={(e) => setAllAllowed(e.target.checked)}
            className="h-4 w-4 mt-1 accent-green-600"
            id="all-box"
          />
          <div>
            <label htmlFor="all-box" className="font-medium cursor-pointer">
              All
            </label>
          </div>
        </div>
        <p className="text-sm text-gray-700 mt-2">
          Allow direct access to your service from the Internet.
        </p>
      </div>

      {/* Second Card: VPC */}
      <div className="border rounded-md p-4">
        <h2 className="mb-3 text-lg font-semibold">VPC</h2>
        <p className="text-sm text-gray-700">
          <span className="font-semibold">Network:</span> None
        </p>
      </div>

      {/* Third Card: Service Mesh */}
      <div className="border rounded-md p-4">
        <h2 className="mb-3 text-lg font-semibold">Service Mesh</h2>
        <p className="text-sm text-gray-700">
          <span className="font-semibold">Service mesh:</span> None
        </p>
      </div>
    </div>
  )
}
