import React, { useState, useContext, useEffect } from 'react';
import Learning from './learning';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "components/ui/dropdown-menu"; // Import dropdown components
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Button } from "components/ui/button"
import { ChevronDown, MessageSquare, ThumbsUp, ThumbsDown } from "lucide-react"

export default function LearningPage() {
  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="flex flex-wrap w-full">
        <div className='flex pb-4'>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="mr-4 w-48 justify-start"> 
              Switch Agent
              <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem>Agent 1</DropdownMenuItem>
            <DropdownMenuItem>Agent 2</DropdownMenuItem>
            <DropdownMenuItem>Agent 3</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Select>
          <SelectTrigger className="w-full sm:w-[360px]">
            <SelectValue placeholder="Choose Action" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="action1">Action 1</SelectItem>
            <SelectItem value="action2">Action 2</SelectItem>
            <SelectItem value="action3">Action 3</SelectItem>
          </SelectContent>
        </Select>
        </div>
        <Learning />
      </div>
    </div>
  );
}