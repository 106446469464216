import { Button } from "components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Switch } from "components/ui/switch"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table"
import { ChevronDown, MessageSquare, ThumbsUp, ThumbsDown } from "lucide-react"
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "components/ui/dropdown-menu"; // Import dropdown components

export default function Learning() {
  return (
    <Card className="w-screen">
      <CardHeader className="flex flex-col items-start space-y-2 pb-2 px-4 sm:px-8">
        <CardTitle className="font-medium sm:text-2xl font-bold">Insights Agent</CardTitle>
        <div className="flex justify-between w-full items-center">
          <div className="text-sm text-muted-foreground">Action: Call SRS pricing and availability API</div>
          <div className="flex items-center space-x-4"> {/* Increased space between items */}
            <div className="flex items-center space-x-2">
              <Switch id="auto-tune" />
              <label htmlFor="auto-tune" className="text-sm font-medium">
                Auto-Tune
              </label>
            </div>
          </div>
        </div>
      </CardHeader>
      <CardContent className="px-4 sm:px-8 py-6">
        <div className="w-full mx-auto">
          {/* <div className="flex flex-wrap gap-2 mb-6">
            <Button variant="outline">Filter 1</Button>
            <Button variant="outline">Filter 2</Button>
            <Button variant="outline">Filter 3</Button>
          </div> */}
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[25%]">Input</TableHead>
                  <TableHead className="w-[25%]">Output</TableHead>
                  <TableHead className="w-[40%]">Feedback</TableHead>
                  <TableHead className="w-[10%]">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {[...Array(8)].map((_, i) => (
                  <TableRow key={i}>
                    <TableCell className="p-2">
                      <div className="h-20 border rounded-md px-4 py-3 bg-background">
                        {/* Content goes here */}
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="h-20 border rounded-md px-4 py-3 bg-background">
                        {/* Content goes here */}
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="h-20 border rounded-md px-4 py-3 bg-background">
                        {/* Content goes here */}
                      </div>
                    </TableCell>
                    <TableCell className="p-2">
                      <div className="flex justify-end space-x-2">
                        <Button variant="ghost" size="icon">
                          <MessageSquare className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="icon">
                          <ThumbsUp className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="icon">
                          <ThumbsDown className="h-4 w-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}