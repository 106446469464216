import React, { useState, useContext } from 'react';
import { AppContext } from 'contexts/AppContext';
import useLocalStorage from 'utils/useLocalStorage';

import MonitoringPage from './monitoring/Index';
import LogsPage from './logs/Index';
import LearningPage from './learning/Index';
import RevisionsPage from './revisions/Index'
import NetworkingPage from './networking/Index'
import ExperimentationPage from './experimentation/Index'

export default function DetailsPage() {
  const { currentDeployment } = useContext(AppContext);

  const tabs = [
    { id: 'monitoring', label: 'Monitoring' },
    { id: 'logs', label: 'Logs' },
    /* { id: 'automated-testing', label: 'Automated Testing' }, */
    { id: 'memory', label: 'Memory' },
    { id: 'learning', label: 'Learning' },
    { id: 'configure', label: 'Configure' },
    { id: 'experimentation', label: 'Experimentation' },
    { id: 'revisions', label: 'Revisions' },
    { id: 'networking', label: 'Networking' },
  ];

  const [activeTab, setActiveTab] = useLocalStorage("active_tab",'monitoring');

  return (
    <div className="w-full h-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      {/* TABS ROW */}
      <div className="flex items-center overflow-x-auto whitespace-nowrap border-b border-gray-200 dark:border-white/10 mb-4">
        {tabs.map((tab) => {
          const isActive = tab.id === activeTab;
          return (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={
                'px-4 py-2 text-sm font-medium ' +
                (isActive
                  ? 'text-blue-600 border-b-2 border-blue-600'
                  : 'text-gray-500 hover:text-gray-700')
              }
            >
              {tab.label}
            </button>
          );
        })}
      </div>

      {/* TAB CONTENT SECTION */}
      <div className="flex flex-wrap w-full h-full pt-4 my-2">
        {activeTab === 'monitoring' && (
          <MonitoringPage/>
        )}
        {activeTab === 'logs' && (
          <LogsPage/>
        )}
        {activeTab === 'automated-testing' && (
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-2">Automated Testing</h2>
            <p className="text-gray-600">Some testing info…</p>
          </div>
        )}
        {activeTab === 'learning' && (
          <LearningPage/>
        )}
        {activeTab === 'configure' && (
          <div className="p-4">
            <h2 className="text-lg font-semibold mb-2">Configure (edit/build)</h2>
            <p className="text-gray-600">Configuration details…</p>
          </div>
        )}
        {activeTab === 'experimentation' && (
          <ExperimentationPage/>
        )}
        {activeTab === 'revisions' && (
          <RevisionsPage/>
        )}
        {activeTab === 'networking' && (
          <NetworkingPage/>
        )}
      </div>
    </div>
  );
}
