import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { useTheme } from 'next-themes';
import logo from 'img/logo.png';
import logo_mini from 'img/logo_mini.png';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarSection,
  SidebarSpacer,
  SidebarItem,
  SidebarLabel,
} from 'components/catalyst/sidebar';
import { MenuItems, FooterItems } from './MenuItems';
import { UserDropdown } from './UserDropdown';
import { SidebarItemWithMenu } from './SidebarItemWithMenu'; 
import { PanelLeft } from 'lucide-react';

export function SidebarContent({ closeSidebar, isMini = false, toggleMini = () => {} }) {
  const { theme } = useTheme();
  const location = useLocation();

  return (
    <Sidebar
      className={`transition-all duration-300 ease-in-out ${isMini ? 'w-18' : 'w-64'}`}
    >
      {/* Header Section */}
      <SidebarHeader>
        <SidebarSection>
          <div className="flex justify-between items-center">
            <Link to="/" onClick={closeSidebar}>
              <img
                src={isMini ? logo_mini : logo}
                className={`py-2 ${theme === 'dark' ? 'invert' : ''} ${
                  isMini ? 'w-6' : 'w-28'
                }`} 
                alt="Logo"
              />
            </Link>
            {/* The button that toggles mini vs full */}
            <button
              onClick={toggleMini}
              className="text-gray-900 hover:text-gray-900 self-start pt-3 max-lg:hidden"
            >
              <PanelLeft className="w-4 h-4" />
            </button>
          </div>
        </SidebarSection>
      </SidebarHeader>

      {/* Body Section */}
      <SidebarBody>
        <SidebarSection>
          <div className={`${isMini ? 'flex flex-col items-center gap-1' : 'flex flex-col gap-1'}`}>
            {MenuItems.map((item) => {
              // Determine if this item matches the current route
              const isActive = location.pathname === item.path;
              return (
                <SidebarItem
                  as={Link}
                  to={item.path}
                  key={item.path}
                  onClick={closeSidebar}
                  current={isActive}
                >
                  <item.icon className="w-5 h-5" />
                  {!isMini && (
                    <SidebarLabel className="text-sx font-weight-600">
                      {item.label}
                    </SidebarLabel>
                  )}
                </SidebarItem>
              );
            })}
          </div>
        </SidebarSection>

        <SidebarSpacer />

        <SidebarSection>
          <div className={`${isMini ? 'flex flex-col items-center gap-1' : 'flex flex-col gap-1'}`}>
            {FooterItems.map((item) => {
              const isActive = location.pathname === item.path;

              return (
                <SidebarItem
                  as={Link}
                  to={item.path}
                  key={item.path}
                  onClick={closeSidebar}
                  current={isActive}
                >
                  <item.icon className="w-5 h-5" />
                  {/* Only show labels if NOT mini */}
                  {!isMini && (
                    <SidebarLabel className="text-sx font-weight-600">
                      {item.label}
                    </SidebarLabel>
                  )}
                </SidebarItem>
              );
            })}
          </div>
        </SidebarSection>
      </SidebarBody>

      <SidebarFooter className="max-lg:hidden">
        <UserDropdown closeSidebar={closeSidebar} isMini={isMini} />
      </SidebarFooter>
    </Sidebar>
  );
}
