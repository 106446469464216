import React, {useState, useEffect, useContext} from "react"
import { Table } from './table/Index'
import { Check, Copy } from 'lucide-react'

export default function RevisionsPage() {

    return (
      <div className="w-full h-[75vh]">
        <div className="flex justify-between">
          <div className="flex gap-3 items-center mb-4">
            <div className='inline-flex items-center justify-center w-6 h-6 rounded-full bg-green-500'>
              <Check size={14} className="text-white" />
            </div>
            <h1 className="text-lg font-semibold items-center">{agent_revisions[0].name}</h1>
          </div>
          <div className="flex gap-3 items-center mb-4">
            <h1 className="text-xs font-s text-gray-800 items-center"><b>URL:</b> https://cosailor-app-backend-dev-586731320826.us-central1.run.app</h1>
            <Copy size={10}/>
          </div>
        </div>
        <Table data={agent_revisions}/>
      </div>
    )
}

export const agent_revisions = [
  {
    name: "cosailor-agent-stage-9877",
    deployed: true,
    traffic: "100% (to latest)",
    deployed_since: "3 days ago",
  },
  {
    name: "cosailor-agent-stage-1248",
    deployed: false,
    traffic: "0%",
    deployed_since: "5 days ago",
  },
  {
    name: "cosailor-agent-stage-3412",
    deployed: false,
    traffic: "0%",
    deployed_since: "7 days ago",
  },
  {
    name: "cosailor-agent-stage-7765",
    deployed: false,
    traffic: "0%",
    deployed_since: "12 days ago",
  },
  {
    name: "cosailor-agent-stage-8932",
    deployed: false,
    traffic: "0%",
    deployed_since: "15 days ago",
  },
  {
    name: "cosailor-agent-stage-2025",
    deployed: false,
    traffic: "0%",
    deployed_since: "20 days ago",
  },
  {
    name: "cosailor-agent-stage-6501",
    deployed: false,
    traffic: "0%",
    deployed_since: "25 days ago",
  },
  {
    name: "cosailor-agent-stage-9999",
    deployed: false,
    traffic: "0%",
    deployed_since: "30 days ago",
  },
  {
    name: "cosailor-agent-stage-9877",
    deployed: false,
    traffic: "0%",
    deployed_since: "3 days ago",
  },
  {
    name: "cosailor-agent-stage-1248",
    deployed: false,
    traffic: "0%",
    deployed_since: "5 days ago",
  },
  {
    name: "cosailor-agent-stage-3412",
    deployed: false,
    traffic: "0%",
    deployed_since: "7 days ago",
  },
  {
    name: "cosailor-agent-stage-7765",
    deployed: false,
    traffic: "0%",
    deployed_since: "12 days ago",
  },
  {
    name: "cosailor-agent-stage-8932",
    deployed: false,
    traffic: "0%",
    deployed_since: "15 days ago",
  },
  {
    name: "cosailor-agent-stage-2025",
    deployed: false,
    traffic: "0%",
    deployed_since: "20 days ago",
  },
  {
    name: "cosailor-agent-stage-6501",
    deployed: false,
    traffic: "0%",
    deployed_since: "25 days ago",
  },
  {
    name: "cosailor-agent-stage-9999",
    deployed: false,
    traffic: "0%",
    deployed_since: "30 days ago",
  },
  {
    name: "cosailor-agent-stage-9877",
    deployed: false,
    traffic: "0%",
    deployed_since: "3 days ago",
  },
  {
    name: "cosailor-agent-stage-1248",
    deployed: false,
    traffic: "0%",
    deployed_since: "5 days ago",
  },
  {
    name: "cosailor-agent-stage-3412",
    deployed: false,
    traffic: "0%",
    deployed_since: "7 days ago",
  },
  {
    name: "cosailor-agent-stage-7765",
    deployed: false,
    traffic: "0%",
    deployed_since: "12 days ago",
  },
  {
    name: "cosailor-agent-stage-8932",
    deployed: false,
    traffic: "0%",
    deployed_since: "15 days ago",
  },
  {
    name: "cosailor-agent-stage-2025",
    deployed: false,
    traffic: "0%",
    deployed_since: "20 days ago",
  },
  {
    name: "cosailor-agent-stage-6501",
    deployed: false,
    traffic: "0%",
    deployed_since: "25 days ago",
  },
  {
    name: "cosailor-agent-stage-9999",
    deployed: false,
    traffic: "0%",
    deployed_since: "30 days ago",
  },
];
