import React, {useState, useEffect} from 'react';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card'
import { Button } from 'components/ui/button'; 
import { Input } from "components/ui/input";
import { Search, Filter } from 'lucide-react';

const ActionPage = () => {
  const actionPackages = [
    {
      created_by: "My Actions",
      name: "gallery-actions",
      description: "Action package that contains several key enterprise actions.",
      version: "1.0.2",
    },
    {
      created_by: "Instalily",
      name: "workday-actions",
      description: "This package connects your agent to Workday to retrieve leave of absence information for a specified worker.",
      version: "0.0.2",
    },
    {
        created_by: "Instalily",
      name: "Actions Bootstrapper",
      description: "Actions for setting up and managing other action packages.",
      version: "1.0.2",
    },
    {
        created_by: "My Actions",
      name: "Agent Connector",
      description: "Actions to connect agents with each other.",
      version: "10.0.0",
    },
    {
        created_by: "Instalily",
      name: "Browsing",
      description: "Get information from websites, and interact with them.",
      version: "1.0.2"
    },
    {
        created_by: "Instalily",
      name: "Email sending",
      description: "Base actions needed to send emails using any SMTP service.",
      version: "1.0.0"
    },
    {
        created_by: "Instalily",
      name: "Excel",
      description: "Create, read and update sheets on local Excel files.",
      version: "3.0.1"
    }
  ];

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      setSearchQuery("");
    }
  };

  return (
    <div className="w-full h-[75vh] overflow-y-auto p-6">
      <h1 className="text-lg font-semibold mb-4">Actions</h1>

      <div className="relative flex items-center mb-4 ">
        <div className="relative flex items-center w-[400px]">
            <Search className="absolute left-3 w-5 h-5 text-gray-500" />
            <Input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
            placeholder="Search"
            className="w-full py-2 pl-10 pr-12 border border-gray-300 rounded-md "
            />
        </div>
        <button className="flex items-center h-[40px] justify-center p-2 border border-gray-300 rounded-md bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2">
            <Filter className="w-5 h-5 text-gray-500" />
        </button>
      </div>
      <div className="h-[75vh] overflow-y-auto">
        <div className="grid grid-cols-1 gap-2">
            {actionPackages.map((packageItem) => (
            <Card key={packageItem.name} className="shadow-md">
                <CardContent className="pb-4">
                <div className="flex items-center mt-4">
                    <span className="text-xs text-gray-400 mr-2">{packageItem.created_by} /</span>
                    <h3 className="text-md font-semibold">{packageItem.name}</h3>
                </div>
                <p className="text-sm text-gray-600">{packageItem.description}</p>
                <span className="text-xs text-gray-400">Version {packageItem.version}</span>
                </CardContent>
            </Card>
            ))}
        </div>
        </div>

    </div>
  );
};

export default ActionPage;
