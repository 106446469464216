import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from "react";
import useLocalStorage from 'utils/useLocalStorage';
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';
import { Link, useLocation } from 'react-router-dom';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

  const multiAgents = [
    { 
      id: 1,
      name: "SRS Cosailor",
      type: "Cron Job",
      run_count_7D: '1',
      description: "Distill customer information to create insights and actions for a sales team.", 
      status: "Normal", 
      date: "1/22/2025 06:04:32",
      last_deployed: "1/22/2025 06:04:32"
    },
    { 
      id: 2,
      name: "United Rentals Website Agent", 
      type: "Application",
      run_count_7D: '',
      description: "Equipment recommendation chat on the Untied Rentals website", 
      status: "Normal", 
      date: "1/22/2025 06:04:32",
      last_deployed: "1/22/2025 06:04:32"
    },
    { 
      id: 3,
      name: "Partstown Service Manual Agent", 
      type: "Cron Job",
      run_count_7D: '3',
      description: "Parse Partstown model service manuals", 
      status: "Paused", 
      date: "1/22/2025 06:04:32",
      last_deployed: "1/22/2025 06:04:32"
    },
    { 
      id: 4,
      name: "Isco Pipe Cosailor", 
      type: "Cron Job",
      run_count_7D: '1',
      description: "Distill customer information to create insights and actions for a sales team.", 
      status: "Error", 
      date: "1/22/2025 06:04:32",
      last_deployed: "1/22/2025 06:04:32"
    },
  ]

  const templates = [
    {
      id: 1,
      title: "SRS Cosailor",
      description: "Distill customer information to create insights and actions for a sales team.",
      tag: "Sales",
      edited: "2 hours ago"
    },
  ];

  const location = useLocation();

  const WorkspaceMatch = location.pathname.match(/^\/workspaces\/(.+)/);
  const BuildMatch = location.pathname.match(/^\/build\/(.+)/);

  const currentId = WorkspaceMatch ? WorkspaceMatch[1] : BuildMatch ? BuildMatch[1] : null;

  const [ currentDeployment, setCurrentDeployment] = useState(null)
  const [ currentBuild, setCurrentBuild] = useState(null)

  useEffect(() => {
    if (currentId) {
      const numericId = parseInt(currentId, 10);

      if (WorkspaceMatch) {
        const foundAgent = multiAgents.find(agent => agent.id === numericId);
        setCurrentDeployment(foundAgent || null);
      } else if (BuildMatch) {
        const foundBuild = templates.find(agent => agent.id === numericId);
        setCurrentBuild(foundBuild || null);
      }
    } else {
      // Clear deployment and build if no match
      setCurrentDeployment(null);
      setCurrentBuild(null);
    }
  }, [currentId]);


  return (
      <AppContext.Provider
      value={{
        currentId, multiAgents, currentDeployment, currentBuild, templates
      }}
      >
      {children}
      </AppContext.Provider>
  );
};
