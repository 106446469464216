import React, { useState, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { themeQuartz } from 'ag-grid-community'

// We'll use a memoized component for performance
const MemoizedAgGrid = React.memo(({ rowData, columnDefs, defaultColDef, statusBar }) => (
  <AgGridReact
    rowData={rowData}
    columnDefs={columnDefs}
    defaultColDef={defaultColDef}
    statusBar={statusBar}
    theme={themeQuartz}
    pagination={true}
  />
))

/**
 * Renders experiment data with columns for:
 * - Experiment ID
 * - Number of Revisions
 * - Data (JSON)
 * - Run Length (days)
 */
export function Table({ data }) {
  // Define the columns that match your `experiments` dataset
  const columns = [
    'experiment_id',
    'number_of_revisions',
    'data',
    'run_length',
  ]

  const labelMap = {
    experiment_id: 'Experiment ID',
    number_of_revisions: 'Number of Revisions',
    data: 'Data',
    run_length: 'Run Length (days)',
  }

  const [columnDefs, setColumnDefs] = useState([])

  useEffect(() => {
    const newDefs = columns.map((col) => {
      const colDef = {
        field: col,
        headerName: labelMap[col] ?? col,
        sortable: true,
        filter: true,
        resizable: true,
      }

      // If it's the "data" field, we can format as JSON
      if (col === 'data') {
        colDef.valueFormatter = (params) => {
          if (!params.value) return ''
          try {
            // Convert the object to a string (pretty or single-line)
            return JSON.stringify(params.value)
          } catch (e) {
            return String(params.value)
          }
        }
      }

      return colDef
    })
    setColumnDefs(newDefs)
  }, [])

  // Default column definition
  const defaultColDef = useMemo(() => ({
    resizable: true,
    filter: true,
    sortable: true,
  }), [])

  // Optional status bar
  const statusBar = useMemo(() => ({
    statusPanels: [
      {
        statusPanel: 'agAggregationComponent',
        align: 'left',
      },
    ],
  }), [])

  return (
    <div className="h-full w-full flex flex-col">
      <div className="flex-1">
        {data && data.length > 0 ? (
          <MemoizedAgGrid
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            statusBar={statusBar}
          />
        ) : (
          <p className="text-center text-gray-500 mt-4">
            No data to display.
          </p>
        )}
      </div>
    </div>
  )
}
