import React, { useState } from 'react';
import { Check, ChevronDown, X, PenSquare, Rocket, Zap, MoreHorizontal } from 'lucide-react';
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Card, CardContent } from "components/ui/card";
import { ScrollArea } from "components/ui/scroll-area";
import { toast } from 'sonner';
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "components/ui/dialog";

import SelectLLM from './llm'
import Actions from './actions'
import Data from './data'

export default function CreateEditAgent({ agent }) {
  const [purposeText, setPurposeText] = useState("Type here...");
  const [instructionsText, setInstructionsText] = useState("Type here...");

  const handleDeploy = () => {
    toast(
      <div className="flex items-center">
        <span>Compliance Agent Successfully Deployed!</span>
        <Button size="sm" className="ml-4" onClick={() => toast.dismiss()}>Close</Button>
      </div>
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();
    //handleClose()
  }

  return (
    <DialogContent className="sm:max-w-[1000px] h-[100vh] sm:max-h-[80vh] flex flex-col">
    <DialogHeader>
      <DialogTitle><h2 className="text-2xl font-semibold">{agent.title} <PenSquare size={20} className="inline ml-2" /></h2></DialogTitle>
      <DialogDescription>
        Configure your {agent.title} settings here.
      </DialogDescription>
    </DialogHeader>
    <div className="flex-grow overflow-hidden">

      <div className="flex flex-col h-full">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <span className="text-sm text-gray-500 mr-2">{agent.action_count || '0'} actions</span>
            <span className="text-sm text-gray-500 mr-2">Version {agent.version || '0'}</span>
          </div>
        </div>

        <ScrollArea className="flex-grow overflow-auto">
          <div className="pr-4 relative">
            <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-green-100"></div> {/* Vertical line */}
            
            {/* Purpose */}
            <div className="flex mb-8">
              <div className="flex-shrink-0 mr-4 relative z-10">
                <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                  <Check size={20} />
                </div>
              </div>
              <div className="flex-grow">
                <h3 className="text-lg font-semibold">Purpose</h3>
                <span className="text-sm text-gray-600">Define the purpose of this agent.</span>
                <Textarea 
                  className="w-full mt-3" 
                  rows={5}
                  value={purposeText}
                  onFocus={() => setPurposeText(purposeText === "Type here..." ? "" : purposeText)}
                  onBlur={() => setPurposeText(purposeText === "" ? "Type here..." : purposeText)}
                  onChange={(e) => setPurposeText(e.target.value)}
                />
              </div>
            </div>

            {/* Instructions */}
            <div className="flex mb-8">
              <div className="flex-shrink-0 mr-4 relative z-10">
                <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                  <Check size={20} />
                </div>
              </div>
              <div className="flex-grow">
                <h3 className="text-lg font-semibold">Instructions</h3>
                <p className="text-sm text-gray-600">Describe the tasks that the agent will accomplish.</p>
                <Textarea 
                  className="w-full mt-3" 
                  rows={5}
                  value={instructionsText}
                  onFocus={() => setInstructionsText(instructionsText === "Type here..." ? "" : instructionsText)}
                  onBlur={() => setInstructionsText(instructionsText === "" ? "Type here..." : instructionsText)}
                  onChange={(e) => setInstructionsText(e.target.value)}
                />
              </div>
            </div>

            {/* Choose LLM */}
            <SelectLLM />

            {/* Actions */}
            <Actions actions={agent.actions}/>

            {/* Data */}
            <Data folders={agent.folders} documents={agent.documents} />

          </div>
        </ScrollArea>

        <div className="mt-6 flex justify-end">
          <DialogClose asChild>
            <Button size="lg" className="text-white text-[15px] font-semibold" onClick={handleDeploy}>
              <Rocket className='w-4 h-4 mr-2' />
              Deploy
            </Button>
          </DialogClose>
        </div>
      </div>
    </div>
  </DialogContent>
  );
}