"use client"

import React from "react"
import { TrendingUp } from "lucide-react"
import {
  BarChart,
  CartesianGrid,
  XAxis,
  Bar
} from "recharts"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "components/ui/chart"

function generateChartData() {
  const data = []
  for (let i = 1; i <= 30; i++) {
    const day = String(i).padStart(2, "0") // "01", "02", etc.
    const dateStr = `2025-01-${day}`
    const randomValue = Math.floor(Math.random() * 300) + 1
    data.push({ date: dateStr, value: randomValue })
  }
  return data
}


function MetricCard({ title, description, color, trending }) {
  // Generate random data just for this chart
  const chartData = React.useMemo(() => generateChartData(), [])

  // Configure the chart color
  const chartConfig = React.useMemo(() => ({
    value: {
      label: title,
      color: "#7393B3", // e.g. "hsl(var(--chart-1))"
    },
  }), [title, color])

  return (
    <Card>
      <CardHeader>
        <CardTitle className="font-medium">{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="date"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              // Show just the day portion, e.g. "01", "02"...
              tickFormatter={(value) => value.slice(-2)}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="value" fill="var(--color-value)" radius={8} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          Trending up by {trending}% this month
          <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing daily data for January 2025
        </div>
      </CardFooter>
    </Card>
  )
}

export default function MonitoringPage() {
  // Define your metrics with distinct colors & trending values
  const metrics = [
    {
      title: "LLM Calls",
      description: "Jan - Jun 2025",
      color: "hsl(var(--chart-1))",
      trending: 5.2,
    },
    {
      title: "Latency",
      description: "Jan - Jun 2025",
      color: "hsl(var(--chart-2))",
      trending: 2.9,
    },
    {
      title: "Cost",
      description: "Jan - Jun 2025",
      color: "hsl(var(--chart-3))",
      trending: 4.1,
    },
    {
      title: "Error Rates",
      description: "Jan - Jun 2025",
      color: "hsl(var(--chart-4))",
      trending: 3.7,
    },
    {
      title: "Output Token #",
      description: "Jan - Jun 2025",
      color: "hsl(var(--chart-5))",
      trending: 1.8,
    },
    {
      title: "Context Usage Rate",
      description: "Jan - Jun 2025",
      color: "hsl(var(--chart-6))",
      trending: 6.4,
    },
  ]

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {metrics.map((metric) => (
          <MetricCard
            key={metric.title}
            title={metric.title}
            description={metric.description}
            color={metric.color}
            trending={metric.trending}
          />
        ))}
      </div>
    </div>
  )
}
