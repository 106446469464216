import { useCallback } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card'; 
import { Button } from 'components/ui/button'; 
import { Input } from 'components/ui/input'; 

export function AgentNode({ data, isConnectable }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  return (
    <Card className="w-[250px] border border-gray-200 rounded-lg shadow-md bg-white relative">
      <CardHeader>
        <h3 className="text-lg font-semibold text-gray-800">{data.name}</h3>
        <p className="text-sm text-gray-600">{data.purpose}</p>
      </CardHeader>
      
      {/* <CardContent className="mt-4">
        <label htmlFor="text" className="block text-sm font-medium text-gray-700">
          Text:
        </label>
        <Input
          id="text"
          name="text"
          onChange={onChange}
          className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        />
      </CardContent> */}

      <div className="absolute top-0 left-0 right-0 bottom-0">
        {/* Top Handle */}
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
            id="t" // Matching the targetHandle 't'
            className="absolute top-0 left-1/2 transform -translate-x-1/2 w-6 h-6 bg-blue-500 rounded-full border-2 border-white"
        />

        {/* Right Handle */}
        <Handle
            type="source"
            position={Position.Right}
            id="r" // Matching the sourceHandle 'r'
            isConnectable={isConnectable}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 w-6 h-6 bg-blue-500 rounded-full border-2 border-white"
        />

        {/* Bottom Handle */}
        <Handle
            type="source"
            position={Position.Bottom}
            id="b" // Matching the sourceHandle 'b'
            isConnectable={isConnectable}
            className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-6 h-6 bg-blue-500 rounded-full border-2 border-white"
        />

        {/* Left Handle */}
        <Handle
            type="target"
            position={Position.Left}
            id="l" // Matching the targetHandle 'l'
            isConnectable={isConnectable}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 w-6 h-6 bg-blue-500 rounded-full border-2 border-white"
        />
      </div>

    </Card>
  );
}
