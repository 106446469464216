"use client"

import React from "react"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select"
import { Button } from "components/ui/button"
import { Table } from "./table/Index"

export default function ExperimentationPage() {
  return (
    <div className="w-full">
      <h1 className="text-lg font-semibold mb-4">Manage Traffic</h1>

      <div className="border border-gray-200 p-4 rounded-md">

      {/* Row 1: Revision 1 / Traffic 1 */}
      <div className="flex flex-wrap items-start gap-4 mb-4">
        <div className="flex flex-col">
          <label htmlFor="rev1" className="mb-1 font-medium text-sm">
            Revision 1
          </label>
          <Select>
            <SelectTrigger id="rev1" className="w-[400px]">
              <SelectValue placeholder="Select revision..." />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="cosailor-agent-stage-9877">
                cosailor-agent-stage-9877
              </SelectItem>
              <SelectItem value="cosailor-agent-stage-1248">
                cosailor-agent-stage-1248
              </SelectItem>
              <SelectItem value="cosailor-agent-stage-6501">
                cosailor-agent-stage-6501
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="traffic1" className="mb-1 font-medium text-sm">
            Traffic 1
          </label>
          <input
            id="traffic1"
            type="text"
            className="w-24 rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="100%"
          />
        </div>
      </div>

      {/* Row 2: Revision 2 / Traffic 2 */}
      <div className="flex flex-wrap items-start gap-4 mb-6">
        <div className="flex flex-col">
          <label htmlFor="rev2" className="mb-1 font-medium text-sm">
            Revision 2
          </label>
          <Select>
            <SelectTrigger id="rev2" className="w-[400px]">
              <SelectValue placeholder="Select revision..." />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="cosailor-agent-stage-3412">
                cosailor-agent-stage-3412
              </SelectItem>
              <SelectItem value="cosailor-agent-stage-8932">
                cosailor-agent-stage-8932
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="traffic2" className="mb-1 font-medium text-sm">
            Traffic 2
          </label>
          <input
            id="traffic2"
            type="text"
            className="w-24 rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="0%"
          />
        </div>
      </div>

      {/* Button to add new revision */}
      <div className="flex gap-4">
      <Button variant="outline" className="w-[200px]">Add New Revision +</Button>
      <Button variant="default" className="w-[130px]">Deploy</Button>
      </div>
      </div>
      
      <div className="flex flex-col dark:border-white/10 mt-8 h-[400px]">
        <h1 className="text-lg font-semibold mt-2 mb-4">Previous Experiments</h1>
        <Table data={experiments}/>
      </div>
    </div>
  )
}

export const experiments = [
    {
      experiment_id: "exp-001",
      number_of_revisions: 3,
      data: {
        trafficSplit: ["40%", "60%"],
        metrics: {
          successRate: 0.85,
          errorRate: 0.15,
        },
      },
      run_length: 10, // days
    },
    {
      experiment_id: "exp-002",
      number_of_revisions: 2,
      data: {
        trafficSplit: ["50%", "50%"],
        metrics: {
          successRate: 0.78,
          errorRate: 0.22,
        },
      },
      run_length: 7,
    },
    {
      experiment_id: "exp-003",
      number_of_revisions: 4,
      data: {
        trafficSplit: ["20%", "30%", "30%", "20%"],
        metrics: {
          successRate: 0.88,
          errorRate: 0.12,
        },
      },
      run_length: 14,
    },
    {
      experiment_id: "exp-004",
      number_of_revisions: 1,
      data: {
        trafficSplit: ["100%"],
        metrics: {
          successRate: 0.92,
          errorRate: 0.08,
        },
      },
      run_length: 5,
    },
    {
      experiment_id: "exp-005",
      number_of_revisions: 3,
      data: {
        trafficSplit: ["40%", "40%", "20%"],
        metrics: {
          successRate: 0.81,
          errorRate: 0.19,
        },
      },
      run_length: 12,
    },
    {
      experiment_id: "exp-006",
      number_of_revisions: 2,
      data: {
        trafficSplit: ["25%", "75%"],
        metrics: {
          successRate: 0.75,
          errorRate: 0.25,
        },
      },
      run_length: 9,
    },
  ];
  