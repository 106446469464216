import React, { useState, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { themeQuartz } from 'ag-grid-community';
import {Pause ,CircleAlert, Check } from 'lucide-react'
import { Link } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

const MemoizedAgGrid = React.memo(({ rowData, columnDefs, defaultColDef, sideBar, statusBar }) => (
  <AgGridReact
    rowData={rowData}
    columnDefs={columnDefs}
    defaultColDef={defaultColDef}
    sideBar={sideBar}
    statusBar={statusBar}
    theme={themeQuartz}
    pagination={true}
  />
))

export function Table({ data }) {
  
  const columns = [
    'agent_id',
    'agent_name',
    'output_to',
    'input_data',
    'output_data',
    'time_taken',
    'time_to_first_token',
    'error',
    'output_token_number',
  ]

  // A friendly label for each field (instead of capitalizing)
  const labelMap = {
    agent_id: 'Agent ID',
    agent_name: 'Agent Name',
    output_to: 'Output To',
    input_data: 'Input Data',
    output_data: 'Output Data',
    time_taken: 'Time Taken (sec)',
    time_to_first_token: 'Time to First Token (sec)',
    error: 'Error',
    output_token_number: 'Output Token #',
  }

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    const newDefs = columns.map((col) => {
      // Basic default config
      const colDef = {
        field: col,
        headerName: labelMap[col] || col, // fallback to col if not in labelMap
        sortable: true,
        filter: true,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
      }

      // If it's input_data or output_data, show JSON
      if (col === 'input_data' || col === 'output_data') {
        colDef.valueFormatter = (params) => {
          if (!params.value) return ''
          try {
            return JSON.stringify(params.value)
          } catch (e) {
            return String(params.value)
          }
        }
      }

      // If it's error, just show "true"/"false" or add styling as needed
      if (col === 'error') {
        colDef.valueFormatter = (params) => (params.value ? 'true' : 'false')
      }

      return colDef
    })
    setColumnDefs(newDefs)
  }, [])

  const defaultColDef = useMemo(() => ({
    resizable: true,
    filter: true,
    sortable: true,
    enablePivot: true,    
    enableValue: true,
    enableRowGroup: true,
  }), []);

  const statusBar = useMemo(() => ({
    statusPanels: [
      {
        statusPanel: 'agAggregationComponent',
        align: 'left',
      },
    ],
  }), []);


  return (
    <div className="h-full w-full flex flex-col mb-4">
      <div className="flex-1">
        {data?.length > 0 ? (
          <MemoizedAgGrid
            rowData={data} 
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            statusBar={statusBar}
          />
        ) : (
          <>
            <p className="text-center text-gray-500 mt-4">No data to display.</p>
          </>
        )}
      </div>
    </div>
  )
}
