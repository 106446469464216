import React, { useState } from 'react';
import { Check, ChevronDown, X, PenSquare, Rocket, Zap, MoreHorizontal } from 'lucide-react';
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Card, CardContent } from "components/ui/card";

export default function SelectLLM({ }) {

  const [selectedLLM, setSelectedLLM] = useState('OpenAI');

  const handleSelectLLM = (llm) => {
    setSelectedLLM(llm);
  };

  const llms = [
    {
      name: "OpenAI",
      displayName: "OpenAI/Azure GPT 4o, GPT4",
      description: "Provide OpenAI API key and complete validation",
      logo: "/img/00.png",
      value: "OpenAI"
    },
    {
      name: "Claude",
      displayName: "Claude Sonnet",
      description: "Provide API key and complete validation",
      logo: "/img/01.png",
      value: "Claude"
    },
    {
      name: "Mistral",
      displayName: "Mistral",
      description: "Provide API key and complete validation",
      logo: "/img/02.webp",
      value: "Mistral"
    }
  ];  

  return (
        <div className="flex mb-8">
            <div className="flex-shrink-0 mr-4 relative z-10">
                <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
                    <Check size={20} />
                </div>
            </div>
            <div className="flex-grow">
                <h3 className="text-lg font-semibold ">Choose LLM</h3>
                <p className="text-gray-600 text-sm">Select the Large Language Model (LLM) that will support your agent.</p>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-3">
                    {llms.map((llm) => (
                        <Card
                            key={llm.value}
                            className={`p-4 ${selectedLLM === llm.value ? 'border-2 border-green-500' : ''}`}
                            onClick={() => handleSelectLLM(llm.value)}
                        >
                            <h4 className="font-semibold">{llm.displayName}</h4>
                            <p className="text-sm text-gray-500">{llm.description}</p>
                            <div className="mt-2 flex justify-between items-center">
                            <img src={llm.logo} alt={`${llm.name} logo`} className="w-8 h-8" />
                            {selectedLLM === llm.value && <Check size={20} className="text-green-500" />}
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
  );
}