import React, { useState, useEffect, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import { themeQuartz } from 'ag-grid-community';
import {Pause ,CircleAlert, Check } from 'lucide-react'
import { Link } from 'react-router-dom';

const MemoizedAgGrid = React.memo(({ rowData, columnDefs, defaultColDef, sideBar, statusBar }) => (
  <AgGridReact
    rowData={rowData}
    columnDefs={columnDefs}
    defaultColDef={defaultColDef}
    sideBar={sideBar}
    statusBar={statusBar}
    theme={themeQuartz}
    pagination={true}
  />
))

export function Table({ selectedData }) {
  
  const columns = ['status', 'name', 'type', 'run_count_7D', 'date', 'last_deployed', 'description',];

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    const newDefs = columns.map((col) => {
      // Basic default config
      const colDef = {
        field: col,
        headerName: col.charAt(0).toUpperCase() + col.slice(1),
        sortable: true,
        filter: true,
        resizable: true,
        enableValue: true,
        enableRowGroup: true,
      };
      
      // If it's the 'status' field, attach a custom renderer
      if (col === 'status') {
        colDef.cellRenderer = StatusCellRenderer; 
        colDef.headerName = '';
        colDef.width = 70;
      }
      if (col === 'date') {
        colDef.headerName = 'Most Recent Run'
      }
      if (col === 'run_count_7D') {
        colDef.headerName = 'Run Count (7D)'
      }
      if (col === 'last_deployed') {
        colDef.headerName = 'Last Deployed'
      }
      if (col === 'name') {
        colDef.cellRenderer = NameLinkCellRenderer;
      }

      return colDef;
    });
  
    setColumnDefs(newDefs);
  }, []);
  

  const defaultColDef = useMemo(() => ({
    resizable: true,
    filter: true,
    sortable: true,
    enablePivot: true,    
    enableValue: true,
    enableRowGroup: true,
  }), []);

  const sideBar = useMemo(() => ({
    toolPanels: [
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      },
    ],
    position: 'right',
    defaultToolPanel: '',
  }), []);

  const statusBar = useMemo(() => ({
    statusPanels: [
      {
        statusPanel: 'agAggregationComponent',
        align: 'left',
      },
    ],
  }), []);


  return (
    <div className="h-full w-full flex flex-col mb-4">
      <div className="flex-1">
        {selectedData?.length > 0 ? (
          <MemoizedAgGrid
            rowData={selectedData} 
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            /* sideBar={sideBar} */
            statusBar={statusBar}
          />
        ) : (
          <>
            <p className="text-center text-gray-500 mt-4">No data to display.</p>
          </>
        )}
      </div>
    </div>
  )
}

function StatusCellRenderer({ value }) {
  switch (value) {
    case 'Normal':
      return (
        <div className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-dark-green">
          <Check size={14} className="text-white" />
        </div>
      );
    case 'Error':
      return (
        <div className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-dark-red text-white">
          <span className="text-white font-bold" style={{ fontSize: '14px' }}>
            !
          </span>
        </div>
      );
    case 'Paused':
      return (
        <div className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-yellow-500">
          <Pause size={14} className="text-white" />
        </div>
      );
    default:
      return <span></span>;
  }
}

function NameLinkCellRenderer(props) {
  const { value, data } = props;

  return (
    <Link to={`/workspaces/${data.id}`} className="text-blue-600 underline">
      {value}
    </Link>
  );
}