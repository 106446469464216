import React, { useState, useContext, useEffect } from 'react';
import { Text } from 'components/catalyst/text';
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { Description, Field, Label } from 'components/catalyst/fieldset';
import { Textarea } from 'components/catalyst/textarea';
import { Listbox, ListboxLabel, ListboxOption } from 'components/catalyst/listbox';
import { AppContext } from 'contexts/AppContext';
import { toast } from 'sonner'; // Add this import
import AgentCard from './agentCard';

export default function AgentsPage() {

  const my_agents = [
    {
      title: "Product Search / Substitution Agent",
      description: "This agent automates product lookups, suggests substitutions for out-of-stock items, and provides real-time data integration to ensure smooth operations.",
      tag: "Sales",
      action_count: 2,
      version: 8,
      documents: [
        { title: "product_catalog_8.csv", size: '4gb', type:'Azure Data Store' },
        { title: "Report.docx", size: '1.5MB', type:'' },
        { title: "Presentation.pptx", size: '3MB', type:'' },
      ],
      folders: [
        { title: "Product PDFs", files: '65' },
        { title: "Supplier Manuals", files: '12' },
      ],
      actions: [
        "Call SRS pricing and availability API",
        "Trigger Slack channel webhook for out of stock products",
        "Dynamically create follow up chips",
        "Surface resources in chat (product cards, PDF's)"
      ]
    },
    {
      title: "Product Tagging Agent",
      description: "Automate the tagging and categorization of products. Utilize AI to assign relevant tags, organize inventory efficiently, and enhance database searchability for better operational management.",
      tag: "DevOps",
      action_count: 2,
      version: 2
    },
    {
      title: "Guardrail Agent",
      description: "Ensure compliance and operational integrity with automated guardrails. Triggers alerts for anomalies, and helps maintain standards through intelligent oversight.",
      tag: "DevOps",
      action_count: 3,
      version: 10
    }
  ]

  const system_agents = [
    {
      title: "Blank Template",
      description: "Start from scratch with a fully customizable agent. Build unique AI actions, integrate your preferred tools, and tailor functionalities to meet your operational needs.",
      tag: "Custom"
    },
    {
      title: "Task Manager",
      description: "Automate and streamline your distribution API interactions. This agent manages data flow, triggers alerts based on set conditions, and enhances operational efficiency with dynamic AI actions.",
      tag: "DevOps"
    },
    {
      title: "Data Manager",
      description: "Automates data synchronization across systems, manages inventory updates, ensures data accuracy, and streamlines data handling processes to keep your product information consistent and up-to-date.",
      tag: "DevOps"
    },
    {
      title: "API Manager",
      description: "Automate and streamline your distribution API interactions. This agent manages data flow, synchronizes inventory information, triggers alerts based on set conditions, and enhances operational efficiency with dynamic AI actions.",
      tag: "DevOps"
    },
    {
      title: "Customer Service Agent",
      description: "Automates responses, managing inquiries, and providing real-time assistance. Supports dynamic actions like generating follow-up messages, surfacing relevant documentation, and triggering notifications to streamline customer interactions.",
      tag: "CustomerService"
    },
    {
      title: "Product Agent",
      description: "Automates product management by integrating pricing and availability APIs, triggering stock alerts, dynamically creating follow-up actions, and surfacing relevant resources in chat. Supports CSV uploads or integration with Azure and Snowflake for catalog data.",
      tag: "Sales"
    }
  ];

  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="min-h-screen">
        <main className="max-w-7xl mx-auto">
          <div className='mb-12 mt-6'>
            <div className="flex justify-between">
              <div>
                <h3 className="text-xl font-semibold mb-2">My Agents</h3>
                <p className="text-gray-600 mb-6">View and edit custom agents.</p>
              </div>
              <Button variant="outline" className="ml-auto">
                Create New Agent +
              </Button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {my_agents.map((agent, index) => (
                <AgentCard key={index} agent={agent} created={true} />
              ))}
            </div>
          </div>
          <div className="mb-12 mt-6">
            <h3 className="text-xl font-semibold mb-2">System Agents</h3>
            <p className="text-gray-600 mb-6">Kickstart your project with pre-built agents that are ready to be deployed.</p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {system_agents.map((agent, index) => (
                  <AgentCard key={index} agent={agent}/>
                ))}
              </div>
          </div>
        </main>
      </div>
    </div>
  );
}