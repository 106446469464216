import React, {useState, useContext, useEffect } from 'react'
import { Text } from 'components/catalyst/text'
import { Card, CardHeader, CardContent, CardFooter } from 'components/ui/card'
import Templates from './templates/templates'
import Drafts from './drafts/drafts'
import BuildFlowPage from './buildflow/Index'

export default function BuildPage() {
    
  return (
    <div className="w-full border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="min-h-screen">
        <main className="max-w-7xl mx-auto">
            <div className='mb-6 mt-6'>
                <h3 className="text-xl font-semibold mb-2">Workspace Drafts</h3>
                <p className="text-gray-600 mb-6">Build a multi-agent system from one of our Instalily templates</p>
                <Drafts />
            </div>
            <div className='mb-6'>
                <h3 className="text-xl font-semibold mb-2">Workspace Templates</h3>
                <p className="text-gray-600 mb-6">Build a multi-agent system from one of our Instalily templates</p>
                <Templates/>
            </div>
        </main>
      </div>
    </div>
  )
}