import React, { useState } from 'react';
import { Check, ChevronDown, X, PenSquare, Rocket, Zap, MoreHorizontal } from 'lucide-react';
import { Button } from "components/ui/button";
import { Attachment, Folder } from './attachment'

export default function Actions({ folders, documents }) {

    return (
      <div className="flex">
        <div className="flex-shrink-0 mr-4 relative z-10">
          <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
            <Check size={20} />
          </div>
        </div>
        <div className="flex-grow">
          <h3 className="text-lg font-semibold">Data</h3>
          <p className="text-sm text-gray-600">Upload and configure data sources.</p>
          <div className="flex flex-wrap gap-4 mt-4">
            {folders && folders.map((folder, index) => (
              <Folder key={index} folder={folder} />
            ))}
            {documents && documents.map((document, index) => (
              <Attachment key={index} document={document} />
            ))}
            <Button onClick={(e) => handleSave(e)} size="sm" className="ml-0 mt-1 h-[35px] w-[35px] rounded-full flex items-center justify-center" variant="outline">
              +
            </Button>
          </div>
        </div>
      </div>
    );
  }