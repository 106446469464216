import React, { useCallback, useMemo, useState } from 'react';
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { AgentNode } from './agent-node/Index'; 

export default function BuildFlowPage() {
  const nodeTypes = useMemo(() => ({ agent: AgentNode }), []);

  // Updated initial nodes with more space
  const initialNodes = [
    { 
      id: '1', 
      type: 'agent', 
      position: { x: 50, y: 200 }, // Adjusted position
      data: { name: "Data Engineer Agent", purpose: "Responsible for managing and optimizing the data pipeline, ensuring data integrity, and enabling data-driven decision-making." }
    },
    { 
      id: '2', 
      type: 'agent', 
      position: { x: 400, y: 100 }, // Adjusted position
      data: { name: "Data Analyst Agent", purpose: "Analyze datasets to derive actionable insights, identify trends, and prepare data visualizations for stakeholders." }
    },
    { 
      id: '3', 
      type: 'agent', 
      position: { x: 400, y: 300 }, // Adjusted position
      data: { name: "Insights Agent", purpose: "Extract insights from the data and provide strategic recommendations to the business based on advanced analytics." }
    },
    { 
      id: '4', 
      type: 'agent', 
      position: { x: 700, y: 100 }, // Adjusted position
      data: { name: "Sales Agent", purpose: "Assist with managing the sales pipeline, communicate with leads, and automate the outreach process." }
    },
    { 
      id: '5', 
      type: 'agent', 
      position: { x: 700, y: 300 }, // Adjusted position
      data: { name: "Marketing Agent", purpose: "Manage marketing campaigns, create and distribute content, and measure campaign performance." }
    },
    { 
      id: '6', 
      type: 'agent', 
      position: { x: 1050, y: 200 }, // Adjusted position
      data: { name: "Customer Support Agent", purpose: "Provide automated support to customers, resolve tickets, and offer solutions based on customer queries." }
    },
  ];

  // Updated initial edges
  const initialEdges = [
    { id: 'edge-1', source: '1', target: '2', sourceHandle: 'r', targetHandle: 'l' }, // Bottom of node 1 to Top of node 2
    { id: 'edge-2', source: '1', target: '3', sourceHandle: 'r', targetHandle: 'l' }, // Right of node 1 to Left of node 3
    { id: 'edge-3', source: '2', target: '4', sourceHandle: 'r', targetHandle: 'l' }, // Top of node 2 to Bottom of node 4
    { id: 'edge-4', source: '3', target: '5', sourceHandle: 'r', targetHandle: 'l' }, // Left of node 3 to Right of node 5
    { id: 'edge-5', source: '4', target: '6', sourceHandle: 'r', targetHandle: 'l' }, // Bottom of node 4 to Top of node 6
    { id: 'edge-6', source: '5', target: '6', sourceHandle: 'r', targetHandle: 'l' }, // Right of node 5 to Left of node 6
  ];

  const [nodes, setNodes] = useState(initialNodes);
  const [edges, setEdges] = useState(initialEdges);

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  return (
      <div className="h-[75vh] w-full border">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
        >
          <Controls />
          <MiniMap />
          <Background variant="dots" gap={12} size={1} />
        </ReactFlow>
      </div>
  );
}
