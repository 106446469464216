import React, { useState } from 'react';
import { Check, ChevronDown, X, PenSquare, Rocket, Zap, MoreHorizontal } from 'lucide-react';
import { Button } from "components/ui/button";
import { Textarea } from "components/ui/textarea";
import { Card, CardContent } from "components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "components/ui/alert";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
  } from "components/ui/dropdown-menu";
  
export default function Actions({ actions }) {

  return (
    <div className="flex mb-8">
    <div className="flex-shrink-0 mr-4 relative z-10">
      <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center text-green-500">
        <Check size={20} />
      </div>
    </div>
    <div className="flex-grow">
      <h3 className="text-lg font-semibold">Actions</h3>
      <div className="flex justify-between items-center mb-4">
        <span className="text-gray-600 text-sm">Apply pre-configured or build a new action from the Action Gallery</span>
        <Button variant="outline" size="sm">+ Add more actions</Button>
      </div>
      {actions && actions.map((action, index) => (
        <Alert key={index} className="flex justify-between items-center mt-2">
          <div className="flex items-center">
            <Zap className="h-4 w-4" />
            <div className="ml-2">
              <AlertTitle>Action</AlertTitle>
              <AlertDescription>
                {action}
              </AlertDescription>
            </div>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem>Edit action</DropdownMenuItem>
              <DropdownMenuItem>Remove action</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </Alert>
      ))}
    </div>
  </div>
  );
}