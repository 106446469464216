import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage, BreadcrumbSeparator } from 'components/ui/breadcrumb';
import { BellDot, UserIcon, HomeIcon, ZoomInIcon, Settings2Icon, Circle, SquareActivity, Waypoints } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { MenuItems, FooterItems } from './MenuItems';
import { AppContext } from 'contexts/AppContext';
import { Heading } from 'components/catalyst/heading';

const AllItems = [...MenuItems, ...FooterItems];

function findBreadcrumbChain(items, path, chain = []) {
  for (const item of items) {
    if (item.path === path) {
      return [...chain, item];
    }
    if (item.subMenu) {
      const subChain = findBreadcrumbChain(item.subMenu, path, [...chain, item]);
      if (subChain) return subChain;
    }
  }
  return null;
}

function isDeploymentRoute(pathname) {
  return /^\/workspaces\/\d+$/.test(pathname);
}

function isBuildRoute(pathname) {
  return /^\/build\/\d+$/.test(pathname);
}

export const Header = () => {
  const { currentDeployment, currentBuild } = useContext(AppContext);

  const location = useLocation();
  const [title, setTitle] = useState('');

  // Grab the full breadcrumb chain, e.g. [Build, Create]
  const breadcrumbChain = useMemo(() => findBreadcrumbChain(AllItems, location.pathname) || [], [location.pathname]);

  const extendedChain = useMemo(() => {
    const chain = [...breadcrumbChain]; // copy of breadcrumbChain

    if (isDeploymentRoute(location.pathname) && currentDeployment) {
      chain.push({
        path: '/workspaces',
        label: 'Workspaces',
        page_title: 'Workspaces',
        icon: SquareActivity,
      },{
        path: location.pathname,
        label: currentDeployment.name,
        icon: Circle,
        page_title: currentDeployment.name,
      });
    } else if (isBuildRoute(location.pathname) && currentBuild) {
      chain.push({
        path: '/build',
        label: 'Build',
        page_title: 'Build',
        icon: Waypoints, 
      },{
        path: location.pathname,
        label: currentBuild.title,
        icon: Circle,
        page_title: currentBuild.title,
      });
    }
    
    return chain;
  }, [currentDeployment, location.pathname, currentBuild]);

  useEffect(() => {
    const updatedTitle = () => {
      if (location.pathname === '/profile') {
        return 'My Profile';
      } else if (location.pathname === '/') {
        return 'Workspaces';
      } else if (location.pathname === '/feedback') {
        return 'Feedback';
      } else if (extendedChain.length > 0) {
        return extendedChain[extendedChain.length - 1].page_title || '';
      } else if (isDeploymentRoute(location.pathname) && currentDeployment) {
        return currentDeployment.name;
      } else if (isBuildRoute(location.pathname) && currentBuild) {
        return currentBuild.title;
      } else {
        return '';
      }
    };

    setTitle(updatedTitle());
  }, [location.pathname, currentDeployment, currentBuild]); 

  return (
    <header>
      <div className='pb-2'>
        <Breadcrumb>
          <BreadcrumbList className="flex items-center">
            {/* Always show Home */}
            <Link to="/">
              <BreadcrumbItem className="flex items-center">
                <HomeIcon className="w-3.5" />
                <BreadcrumbPage>Home</BreadcrumbPage>
              </BreadcrumbItem>
            </Link>

            {/* Home & special-cased routes */}
            {location.pathname === '/' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="flex items-center">
                  <UserIcon className="w-3.5" />
                  <BreadcrumbPage>Deployments</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}

            {location.pathname === '/profile' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="flex items-center">
                  <UserIcon className="w-3.5" />
                  <BreadcrumbPage>My Profile</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}

            {location.pathname === '/feedback' && (
              <>
                <BreadcrumbSeparator />
                <BreadcrumbItem className="flex items-center">
                  <Settings2Icon className="w-3.5" />
                  <BreadcrumbPage>Feedback</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
            
            {extendedChain.map((item, idx) => {
              const isLast = idx === extendedChain.length - 1;
              return (
                <React.Fragment key={`${item.path}-${idx}`}>
                  <BreadcrumbSeparator />
                  {!isLast ? (
                    <Link to={item.path}>
                      <BreadcrumbItem className="flex items-center">
                        {item.icon && <item.icon className="w-3.5" />}
                        <BreadcrumbPage>{item.page_title || item.label}</BreadcrumbPage>
                      </BreadcrumbItem>
                    </Link>
                  ) : (
                    <BreadcrumbItem className="flex items-center">
                      {item.icon && <item.icon className="w-3.5" />}
                      <BreadcrumbPage>{item.page_title || item.label}</BreadcrumbPage>
                    </BreadcrumbItem>
                  )}
                </React.Fragment>
              );
            })}
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 pt-2 dark:border-white/10">
        <span className="text-[28px] font-medium">{title || ""}</span>
        {/* <Heading>{title || "Deployments"}</Heading> */}
        <div className="flex items-center ml-auto space-x-4">
          {/* 
          <Popover>
            <PopoverTrigger><Settings2Icon className="w-6 h-6" /></PopoverTrigger>
            <PopoverContent>
              <CustomerSettings />
            </PopoverContent>
          </Popover>
          */}
          {/* <Link to="/feedback">
            <BookText />
          </Link> */}
          {/* <Popover>
            <PopoverTrigger><BellDot className="w-6 h-6" /></PopoverTrigger>
            <PopoverContent>
              <NotificationDropdown />
            </PopoverContent>
          </Popover> */}
          {/* <Cosailor /> */}
        </div>
      </div>
    </header>
  );
};