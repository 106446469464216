// MenuItems.js
import { HomeIcon, SparklesIcon, CheckCircleIcon, Square3Stack3DIcon, PencilSquareIcon } from '@heroicons/react/20/solid';
import { Home, Monitor, Activity, User2Icon, ActivityIcon, Users, User, SquareLibrary, Settings,
  LayoutPanelTop, Workflow, Database, Waypoints, SquareActivity, CircleArrowRight, NotepadTextDashed, Pickaxe,
  CircleHelp, Logs
 } from 'lucide-react';

 export const MenuItems = [
  {
    path: '/workspaces',
    label: 'Workspaces',
    page_title: 'Workspaces',
    icon: SquareActivity,
  },
  {
    path: '/build',
    label: 'Build',
    page_title: 'Build',
    icon: Waypoints,
  },
  {
    path: '/composable-agents',
    label: 'Composable Agents',
    page_title: 'Composable Agents',
    icon: Workflow,
  },
  {
    path: '/actions',
    label: 'Actions',
    page_title: 'Actions',
    icon: CircleArrowRight,
  },
  {
    path: '/data-layer',
    label: 'Data Layer',
    page_title: 'Data Layer',
    icon: Database,
  }, 
  {
    path: '/settings',
    label: 'Settings',
    page_title: 'Settings',
    icon: Settings,
  },
]

export const FooterItems = [
  {
    path: '/support',
    label: 'Support',
    page_title: 'Support',
    icon: CircleHelp,
  }, 
  {
    path: '/logs',
    label: 'Change Log',
    page_title: 'Change Log',
    icon: Logs,
  }, 
];