import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, AvatarFallback, AvatarImage } from "components/catalyst/avatar";
import { SidebarItem } from 'components/catalyst/sidebar';
import { ChevronUpIcon, ArrowRightStartOnRectangleIcon, LightBulbIcon, UserIcon } from '@heroicons/react/16/solid';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from 'components/catalyst/dropdown';
import { AppContext } from 'contexts/AppContext';
import { ModeToggle } from './ModeToggle';
import { useAuth0 } from '@auth0/auth0-react';

export function UserDropdown({ closeSidebar, isMini }) {
  const { user, logEvent } = useContext(AppContext)
  const { logout } = useAuth0()

  const handleSignOut = () => {
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return (
    <Dropdown>
      <DropdownButton as="div">
        <div className="flex flex min-w-0 w-full items-center gap-3">
          <Avatar initials="CC" className="w-10 h-10 text-sm mx-auto" />
          {/* Conditionally render user info if not mini */}
          {!isMini && (
            <>
              <div className="min-w-0 flex-1">
                <span className="block truncate text-sm font-medium text-zinc-950 dark:text-white">
                  Cristin Connerney
                </span>
                <span className="block truncate text-xs font-normal text-zinc-500 dark:text-zinc-400">
                  cristin@instalily.ai
                </span>
              </div>
              <ChevronUpIcon className="h-5 w-5" />
            </>
          )}
        </div>
      </DropdownButton>
      <DropdownMenu className="min-w-64" anchor="top start">
        <DropdownItem>
          <Link
            to="/profile"
            onClick={() => {
              console.log("Navigating to profile")
              closeSidebar?.()
            }}
            className="flex items-center gap-2 w-full"
          >
            <UserIcon className="h-5 w-5" />
            <DropdownLabel>My profile</DropdownLabel>
          </Link>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem>
          <Link
            to="/feedback"
            onClick={() => {
              console.log("Navigating to feedback")
              closeSidebar?.()
            }}
            className="flex items-center gap-2 w-full"
          >
            <LightBulbIcon className="h-5 w-5" />
            <DropdownLabel>Share feedback</DropdownLabel>
          </Link>
        </DropdownItem>
        <DropdownItem className="hidden md:flex">
          <ModeToggle />
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem>
          <button
            onClick={handleSignOut}
            className="flex items-center gap-2 w-full"
          >
            <ArrowRightStartOnRectangleIcon className="h-5 w-5" />
            <DropdownLabel>Sign out</DropdownLabel>
          </button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}
