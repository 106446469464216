import React, { useState, useContext } from "react";
import { Button } from "components/ui/button"; // Shadcn Button
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "components/ui/card"; // Shadcn Card
import { DropdownMenu, DropdownMenuItem, DropdownMenuTrigger, DropdownMenuContent } from "components/ui/dropdown-menu"; // Shadcn Dropdown
import { Badge } from "components/ui/badge"; // Shadcn Badge
import { WorkflowIcon } from 'lucide-react'; // Lucide React Workflow Icon
import { Edit, Download, Copy, Trash2, MoreHorizontal } from 'lucide-react'; // Lucide React icons for actions
import { Link } from 'react-router-dom';
import { AppContext } from "contexts/AppContext";

export default function Drafts() {
  const { templates } = useContext(AppContext)

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {templates.map((agent, index) => (
        <TemplateCard key={index} agent={agent} />
      ))}
    </div>
  );
}

export function TemplateCard({ agent }) {
  return (
    <Card className="flex flex-col">
      <CardHeader className="flex items-center space-x-4">
        {/* Icon and Background */}
        <div className="flex justify-between w-full">
          <div className="flex items-center justify-center w-12 h-12 bg-blue-600 text-white rounded-lg mr-2">
            <WorkflowIcon className="w-6 h-6" />
          </div>

          {/* Template Title and Edited Time */}
          <div className="flex-grow">
            <CardTitle className="text-lg font-semibold">{agent.title}</CardTitle>
            <p className="text-sm text-gray-500">{`Edited ${agent.edited}`}</p>
          </div>

          {/* Dropdown for Details Button */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="w-8 h-8 p-0 text-gray-500 hover:bg-gray-200">
                <MoreHorizontal className="w-5 h-5" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-48">
              <DropdownMenuItem>
                <Edit className="w-4 h-4 mr-2" /> Edit Details
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Download className="w-4 h-4 mr-2" /> Download
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Copy className="w-4 h-4 mr-2" /> Duplicate
              </DropdownMenuItem>
              <DropdownMenuItem>
                <Trash2 className="w-4 h-4 mr-2" /> Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </CardHeader>

      {/* Description and Tag */}
      <CardContent className="flex-grow">
        <p className="text-sm text-gray-600">{agent.description}</p>
      </CardContent>

      <CardFooter>
        <div className='flex justify-between w-full'>
          <Badge variant="secondary" className="text-sm">{`#${agent.tag}`}</Badge>
          <Link to={`/build/${agent.id}`} className="text-blue-600 underline">
            <Button variant="outline">
              Edit
            </Button>
          </Link>
        </div>
      </CardFooter>
    </Card>
  );
}
