import React, { useState } from "react";
import { Table } from './table/Index';
import { Input } from "components/ui/input";
import { Search, Filter } from 'lucide-react'; // Importing both the search and filter icons

export default function LogsPage() {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchKeyPress = (event) => {
    if (event.key === "Enter") {
      // Clear the input when the Enter key is pressed
      setSearchQuery("");
    }
  };

  return (
    <div className="w-full h-[75vh]">
      <h1 className="text-lg font-semibold mb-4">AI Agent Logs</h1>

      <div className="relative flex items-center mb-4 ">
        <div className="relative flex items-center w-[400px]">
            <Search className="absolute left-3 w-5 h-5 text-gray-500" />
            <Input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
            placeholder="Search"
            className="w-full py-2 pl-10 pr-12 border border-gray-300 rounded-md "
            />
        </div>
        <button className="flex items-center h-[40px] justify-center p-2 border border-gray-300 rounded-md bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 ml-2">
            <Filter className="w-5 h-5 text-gray-500" />
        </button>
      </div>
      
      <Table data={agent_logs}/>
      </div>
    )
  }

  const agent_logs = [
    {
      agent_id: 1,
      agent_name: "SalesGPT",
      output_to: 2, // This might reference another agent by ID
      input_data: {
        query: "Generate a summary of sales data",
        parameters: {
          dateRange: "2025-01-01 to 2025-01-31",
          region: "North America",
        },
      },
      output_data: {
        result: "Summary: Total sales = $1,234,567. Top product = 'Widget A'.",
      },
      time_taken: 2.45,
      time_to_first_token: 0.28,
      error: false,
      output_token_number: 134,
    },
    {
      agent_id: 2,
      agent_name: "CustomerSupportBot",
      output_to: 0, // 0 might mean it doesn't feed another agent
      input_data: {
        request: "Customer complaint about delayed shipping",
        details: {
          customerId: 918273,
          orderId: "A1001",
        },
      },
      output_data: {
        response: "Apologized to the customer and offered a discount code.",
      },
      time_taken: 3.12,
      time_to_first_token: 0.61,
      error: false,
      output_token_number: 89,
    },
    {
      agent_id: 3,
      agent_name: "AnalyticsAgent",
      output_to: 1,
      input_data: {
        metrics: ["conversionRate", "bounceRate"],
        timeframe: "Last 7 days",
      },
      output_data: {
        result: {
          conversionRate: "3.9%",
          bounceRate: "45%",
        },
      },
      time_taken: 1.87,
      time_to_first_token: 0.42,
      error: false,
      output_token_number: 56,
    },
    {
      agent_id: 4,
      agent_name: "InventoryBot",
      output_to: 3,
      input_data: {
        action: "Check stock levels",
        sku: "XYZ-100",
      },
      output_data: {
        status: "In Stock",
        quantity: 140,
      },
      time_taken: 2.14,
      time_to_first_token: 0.35,
      error: false,
      output_token_number: 72,
    },
    {
      agent_id: 5,
      agent_name: "RecommendationAgent",
      output_to: 0,
      input_data: {
        userId: 5555,
        interests: ["electronics", "gaming"],
      },
      output_data: {
        recommendations: ["New VR headset", "Gaming mouse"],
      },
      time_taken: 2.77,
      time_to_first_token: 0.32,
      error: false,
      output_token_number: 64,
    },
    {
      agent_id: 2,
      agent_name: "CustomerSupportBot",
      output_to: 1,
      input_data: {
        request: "Refund request for damaged item",
        orderId: "C2002",
      },
      output_data: {
        response: "Refund approved. Issuing $89.99 to customer.",
      },
      time_taken: 2.02,
      time_to_first_token: 0.22,
      error: false,
      output_token_number: 48,
    },
    {
      agent_id: 1,
      agent_name: "SalesGPT",
      output_to: 0,
      input_data: {
        query: "Create cross-sell suggestions",
        productId: "ABC-123",
      },
      output_data: {
        crossSellIdeas: ["Accessory X", "Upgrade Kit Y"],
      },
      time_taken: 3.09,
      time_to_first_token: 0.78,
      error: false,
      output_token_number: 151,
    },
    {
      agent_id: 3,
      agent_name: "AnalyticsAgent",
      output_to: 5,
      input_data: {
        metrics: ["salesFunnel", "avgSessionTime"],
        timeframe: "Past 14 days",
      },
      output_data: {
        result: {
          salesFunnel: "Visitors -> Leads -> Sales: 1000 -> 200 -> 45",
          avgSessionTime: "3m 25s",
        },
      },
      time_taken: 1.65,
      time_to_first_token: 0.29,
      error: false,
      output_token_number: 75,
    },
    {
      agent_id: 5,
      agent_name: "RecommendationAgent",
      output_to: 4,
      input_data: {
        userId: 999,
        interests: ["books", "cooking"],
      },
      output_data: {
        recommendations: ["Cookbook: 100 Healthy Recipes", "Baking Tools Set"],
      },
      time_taken: 3.44,
      time_to_first_token: 0.64,
      error: true, // small % of these are errors
      output_token_number: 0,
    },
    {
      agent_id: 4,
      agent_name: "InventoryBot",
      output_to: 0,
      input_data: {
        action: "Update stock levels",
        sku: "XYZ-300",
        adjustment: -20,
      },
      output_data: {
        status: "Stock updated successfully",
        newQty: 120,
      },
      time_taken: 2.51,
      time_to_first_token: 0.47,
      error: false,
      output_token_number: 34,
    },
    {
        agent_id: 1,
        agent_name: "SalesGPT",
        output_to: 2, // This might reference another agent by ID
        input_data: {
          query: "Generate a summary of sales data",
          parameters: {
            dateRange: "2025-01-01 to 2025-01-31",
            region: "North America",
          },
        },
        output_data: {
          result: "Summary: Total sales = $1,234,567. Top product = 'Widget A'.",
        },
        time_taken: 2.45,
        time_to_first_token: 0.28,
        error: false,
        output_token_number: 134,
      },
      {
        agent_id: 2,
        agent_name: "CustomerSupportBot",
        output_to: 0, // 0 might mean it doesn't feed another agent
        input_data: {
          request: "Customer complaint about delayed shipping",
          details: {
            customerId: 918273,
            orderId: "A1001",
          },
        },
        output_data: {
          response: "Apologized to the customer and offered a discount code.",
        },
        time_taken: 3.12,
        time_to_first_token: 0.61,
        error: false,
        output_token_number: 89,
      },
      {
        agent_id: 3,
        agent_name: "AnalyticsAgent",
        output_to: 1,
        input_data: {
          metrics: ["conversionRate", "bounceRate"],
          timeframe: "Last 7 days",
        },
        output_data: {
          result: {
            conversionRate: "3.9%",
            bounceRate: "45%",
          },
        },
        time_taken: 1.87,
        time_to_first_token: 0.42,
        error: false,
        output_token_number: 56,
      },
      {
        agent_id: 4,
        agent_name: "InventoryBot",
        output_to: 3,
        input_data: {
          action: "Check stock levels",
          sku: "XYZ-100",
        },
        output_data: {
          status: "In Stock",
          quantity: 140,
        },
        time_taken: 2.14,
        time_to_first_token: 0.35,
        error: false,
        output_token_number: 72,
      },
      {
        agent_id: 5,
        agent_name: "RecommendationAgent",
        output_to: 0,
        input_data: {
          userId: 5555,
          interests: ["electronics", "gaming"],
        },
        output_data: {
          recommendations: ["New VR headset", "Gaming mouse"],
        },
        time_taken: 2.77,
        time_to_first_token: 0.32,
        error: false,
        output_token_number: 64,
      },
      {
        agent_id: 2,
        agent_name: "CustomerSupportBot",
        output_to: 1,
        input_data: {
          request: "Refund request for damaged item",
          orderId: "C2002",
        },
        output_data: {
          response: "Refund approved. Issuing $89.99 to customer.",
        },
        time_taken: 2.02,
        time_to_first_token: 0.22,
        error: false,
        output_token_number: 48,
      },
      {
        agent_id: 1,
        agent_name: "SalesGPT",
        output_to: 0,
        input_data: {
          query: "Create cross-sell suggestions",
          productId: "ABC-123",
        },
        output_data: {
          crossSellIdeas: ["Accessory X", "Upgrade Kit Y"],
        },
        time_taken: 3.09,
        time_to_first_token: 0.78,
        error: false,
        output_token_number: 151,
      },
      {
        agent_id: 3,
        agent_name: "AnalyticsAgent",
        output_to: 5,
        input_data: {
          metrics: ["salesFunnel", "avgSessionTime"],
          timeframe: "Past 14 days",
        },
        output_data: {
          result: {
            salesFunnel: "Visitors -> Leads -> Sales: 1000 -> 200 -> 45",
            avgSessionTime: "3m 25s",
          },
        },
        time_taken: 1.65,
        time_to_first_token: 0.29,
        error: false,
        output_token_number: 75,
      },
      {
        agent_id: 5,
        agent_name: "RecommendationAgent",
        output_to: 4,
        input_data: {
          userId: 999,
          interests: ["books", "cooking"],
        },
        output_data: {
          recommendations: ["Cookbook: 100 Healthy Recipes", "Baking Tools Set"],
        },
        time_taken: 3.44,
        time_to_first_token: 0.64,
        error: true, // small % of these are errors
        output_token_number: 0,
      },
      {
        agent_id: 4,
        agent_name: "InventoryBot",
        output_to: 0,
        input_data: {
          action: "Update stock levels",
          sku: "XYZ-300",
          adjustment: -20,
        },
        output_data: {
          status: "Stock updated successfully",
          newQty: 120,
        },
        time_taken: 2.51,
        time_to_first_token: 0.47,
        error: false,
        output_token_number: 34,
      },
]